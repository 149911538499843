import React, { FC, useEffect } from "react";

import { KButton } from "@keepeek/refront-components";
import { PageOverrideKey } from "@keepeek/refront-customers";
import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";

import commonEn from "../../public/locales/en/common.json";
import commonFr from "../../public/locales/fr/common.json";
import { getCustomerOverrideComponent } from "../lib/overrides";
import { SentryBreadcrumbCategory } from "../lib/sentry/breadcrumbs";

type Custom404Props = {};

const Custom404: FC<Custom404Props> = function () {
  const { locale } = useRouter();

  // (JBO) We have to create our own TFunction because the custom404 can't have getSSRProps to init translation
  // I am doing it the lazy way, because we have to update this page later with this ticket: https://jira.keepeek.com/browse/RF-1094
  // #quickwin
  const t = (key: string): string => {
    const localizedCommon = locale === "en" ? commonEn : commonFr;
    return localizedCommon[key];
  };

  useEffect(() => {
    if (window) {
      // Send an exception to Sentry anyway
      Sentry.captureException(new Error("404!"), (scope) =>
        scope.setExtra("location Info", window.location),
      );
    }
  }, []);

  const handleClick = () => {
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbCategory.USER_INTERACTION,
      message: "User leave the 404 page",
    });
    // We can't use the router method here because it won't reload the page
    // For now (because we use KC in SSR), we need to reload the browser navigation to have KC in
    window.location.href = "/";
  };

  return (
    <Box
      className="Custom404 Custom404-root"
      style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box className="Custom404-content" style={{ textAlign: "center" }}>
        <Box style={{ marginBottom: "2rem" }}>
          <Typography className="Custom404-title" variant="h1">
            {t("custom404.content.title")}
          </Typography>
          <Typography className="Custom404-description" variant="h6">
            {t("custom404.content.description")}
          </Typography>
        </Box>
        <KButton className="Custom404-button" onClick={handleClick} variant="outlined">
          {t("custom404.content.action.label")}
        </KButton>
      </Box>
    </Box>
  );
};

export default getCustomerOverrideComponent(PageOverrideKey.Custom404, Custom404);
